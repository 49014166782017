import Vue from "vue";
import Router from "vue-router";
import Index from "./Index";
import Test from "./Test";

import Home375 from "./small/Home375";
import Home1024 from "./middle/Home1024";
import Home1920 from "./lager/Home1920";

import About375 from "./small/About375";
import About1024 from "./middle/About1024";
import About1920 from "./lager/About1920";

import News375 from "./small/News375";
import News1024 from "./middle/News1024";
import News1920 from "./lager/News1920";

import Service375 from "./small/Service375";
import Service1024 from "./middle/Service1024";
import Service1920 from "./lager/Service1920";

import Contact375 from "./small/Contact375";
import Contact1024 from "./middle/Contact1024";
import Contact1920 from "./lager/Contact1920";

import NewsDetail375 from "./small/NewsDetail375";
import NewsDetail1024 from "./middle/NewsDetail1024";
import NewsDetail1920 from "./lager/NewsDetail1920";

import DiamondReport375 from "./small/report/DiamondReport375";
import DiamondReport1024 from "./middle/report/DiamondReport1024";
import DiamondReport1920 from "./lager/report/DiamondReport1920";

import GemstoneReport375 from "./small/report/GemstoneReport375";
import GemstoneReport1024 from "./middle/report/GemstoneReport1024";
import GemstoneReport1920 from "./lager/report/GemstoneReport1920";

import PearlReport375 from "./small/report/PearlReport375";
import PearlReport1024 from "./middle/report/PearlReport1024";
import PearlReport1920 from "./lager/report/PearlReport1920";

import OtherGemstoneReport375 from "./small/report/OtherGemstoneReport375";
import OtherGemstoneReport1024 from "./middle/report/OtherGemstoneReport1024";
import OtherGemstoneReport1920 from "./lager/report/OtherGemstoneReport1920";

import PremiumReport375 from "./small/report/PremiumReport375";
import PremiumReport1024 from "./middle/report/PremiumReport1024";
import PremiumReport1920 from "./lager/report/PremiumReport1920";

import Price375 from "./small/Price375";
import Price1024 from "./middle/Price1024";
import Price1920 from "./lager/Price1920";

import Reg375 from "./small/Reg375";
import Reg1024 from "./middle/Reg1024";
import Reg1920 from "./lager/Reg1920";

import Terms375 from "./small/Terms375";
import Terms1024 from "./middle/Terms1024";
import Terms1920 from "./lager/Terms1920";

import {
  homeData,
  aboutData,
  newsData,
  serviceData,
  contactData,
  newsDetailData,
  diamondReportData,
  gemstoneReportData,
  pearlReportData,
  otherGemstoneReportData,
  premiumReportData,

  regData,
  termsAndConditionsData,
} from "./data";

import { 
  price375Data,
  price1024Data,
  price1920Data,
} from "./price-data";

Vue.use(Router);

export default new Router({
  // mode: "history",
  mode: "hash",
  routes: [
    {
      path: "/",
      component: Index,
    },
    {
      path: "/test",
      component: Test,
    },
    {
      path: "/s/home",
      component: Home375,
      props: { ...homeData },
    },
    {
      path: "/m/home",
      component: Home1024,
      props: { ...homeData },
    },
    {
      path: "/l/home",
      component: Home1920,
      props: { ...homeData },
    },
    {
      path: "/s/about",
      component: About375,
      props: { ...aboutData },
    },
    {
      path: "/m/about",
      component: About1024,
      props: { ...aboutData },
    },
    {
      path: "/l/about",
      component: About1920,
      props: { ...aboutData },
    },
    {
      path: "/s/news",
      component: News375,
      props: { ...newsData },
    },
    {
      path: "/m/news",
      component: News1024,
      props: { ...newsData },
    },
    {
      path: "/l/news",
      component: News1920,
      props: { ...newsData },
    },
    {
      path: "/s/service",
      component: Service375,
      props: { ...serviceData },
    },
    {
      path: "/m/service",
      component: Service1024,
      props: { ...serviceData },
    },
    {
      path: "/l/service",
      component: Service1920,
      props: { ...serviceData },
    },
    {
      path: "/s/contact",
      component: Contact375,
      props: { ...contactData },
    },
    {
      path: "/m/contact",
      component: Contact1024,
      props: { ...contactData },
    },
    {
      path: "/l/contact",
      component: Contact1920,
      props: { ...contactData },
    },
    {
      path: "/s/newsDetail",
      component: NewsDetail375,
      props: { ...newsDetailData },
    },
    {
      path: "/m/newsDetail",
      component: NewsDetail1024,
      props: { ...newsDetailData },
    },
    {
      path: "/l/newsDetail",
      component: NewsDetail1920,
      props: { ...newsDetailData },
    },
    {
      path: "/s/DiamondReport",
      component: DiamondReport375,
      props: { ...diamondReportData },
    },
    {
      path: "/m/DiamondReport",
      component: DiamondReport1024,
      props: { ...diamondReportData },
    },
    {
      path: "/l/DiamondReport",
      component: DiamondReport1920,
      props: { ...diamondReportData },
    },
    {
      path: "/s/GemstoneReport",
      component: GemstoneReport375,
      props: { ...gemstoneReportData },
    },
    {
      path: "/m/GemstoneReport",
      component: GemstoneReport1024,
      props: { ...gemstoneReportData },
    },
    {
      path: "/l/GemstoneReport",
      component: GemstoneReport1920,
      props: { ...gemstoneReportData },
    },
    {
      path: "/s/PearlReport",
      component: PearlReport375,
      props: { ...pearlReportData },
    },
    {
      path: "/m/PearlReport",
      component: PearlReport1024,
      props: { ...pearlReportData },
    },
    {
      path: "/l/PearlReport",
      component: PearlReport1920,
      props: { ...pearlReportData },
    },
    {
      path: "/s/OtherGemstoneReport",
      component: OtherGemstoneReport375,
      props: { ...otherGemstoneReportData },
    },
    {
      path: "/m/OtherGemstoneReport",
      component: OtherGemstoneReport1024,
      props: { ...otherGemstoneReportData },
    },
    {
      path: "/l/OtherGemstoneReport",
      component: OtherGemstoneReport1920,
      props: { ...otherGemstoneReportData },
    },
    {
      path: "/s/PremiumReport",
      component: PremiumReport375,
      props: { ...premiumReportData },
    },
    {
      path: "/m/PremiumReport",
      component: PremiumReport1024,
      props: { ...premiumReportData },
    },
    {
      path: "/l/PremiumReport",
      component: PremiumReport1920,
      props: { ...premiumReportData },
    },
    {
      path: "/s/price",
      component: Price375,
      props: { ...price375Data },
    },
    {
      path: "/m/price",
      component: Price1024,
      props: { ...price1024Data },
    },
    {
      path: "/l/price",
      component: Price1920,
      props: { ...price1920Data },
    },
    {
      path: "/s/reg",
      component: Reg375,
      props: { ...regData },
    },
    {
      path: "/m/reg",
      component: Reg1024,
      props: { ...regData },
    },
    {
      path: "/l/reg",
      component: Reg1920,
      props: { ...regData },
    },
    {
      path: "/s/terms-and-conditions",
      component: Terms375,
      props: { ...termsAndConditionsData },
    },
    {
      path: "/m/terms-and-conditions",
      component: Terms1024,
      props: { ...termsAndConditionsData },
    },
    {
      path: "/l/terms-and-conditions",
      component: Terms1920,
      props: { ...termsAndConditionsData },
    },
  ],
});
