import { globalData } from "./data";

export const tag10241Data = {
  x029: "Carat Weight",
};

export const tag10242Data = {
  x029: "Carat Weight",
};

export const fee210Data = {
  number: "30",
  className: "",
};

export const tag102422Data = {
  text15: "≤0.29",
  fee2Props: fee210Data,
};

export const fee211Data = {
  number: "35",
  className: "",
};

export const tag102423Data = {
  text15: "0.30-0.49",
  fee2Props: fee211Data,
};

export const fee212Data = {
  number: "40",
  className: "",
};

export const tag102424Data = {
  text15: "0.50-0.99",
  fee2Props: fee212Data,
};

export const fee213Data = {
  number: "42/ct",
  className: "",
};

export const tag102425Data = {
  text15: "1.00+",
  fee2Props: fee213Data,
};

export const tag10243Data = {
  x029: "Carat Weight",
};

export const tag10244Data = {
  x029: "Carat Weight",
};

export const fee214Data = {
  number: "27",
  className: "",
};

export const tag102426Data = {
  text15: "≤0.49",
  fee2Props: fee214Data,
};

export const fee215Data = {
  number: "32",
  className: "",
};

export const tag102427Data = {
  text15: "0.50-0.99",
  fee2Props: fee215Data,
};

export const fee216Data = {
  number: "37",
  className: "",
};

export const tag102428Data = {
  text15: "1.00-1.99",
  fee2Props: fee216Data,
};

export const fee217Data = {
  number: "45",
  className: "",
};

export const tag102429Data = {
  text15: "2.00-2.99",
  fee2Props: fee217Data,
};

export const fee218Data = {
  number: "50",
  className: "",
};

export const tag1024210Data = {
  text15: "3.00-4.99",
  fee2Props: fee218Data,
};

export const fee219Data = {
  number: "60",
  className: "",
};

export const tag1024211Data = {
  text15: "5.00-9.99",
  fee2Props: fee219Data,
};

export const fee220Data = {
  number: "80",
  className: "",
};

export const tag1024212Data = {
  text15: "10.00-19.99",
  fee2Props: fee220Data,
};

export const fee221Data = {
  number: "100",
  className: "",
};

export const tag1024213Data = {
  text15: "20.00-49.99",
  fee2Props: fee221Data,
};

export const fee222Data = {
  number: "120",
  className: "",
};

export const tag1024214Data = {
  text15: "50.00-99.99",
  fee2Props: fee222Data,
};

export const fee223Data = {
  number: "150",
  className: "",
};

export const tag1024215Data = {
  text15: "100.00-199.99",
  fee2Props: fee223Data,
};

export const fee224Data = {
  number: "200",
  className: "",
};

export const tag1024216Data = {
  text15: "200.00-499.99",
  fee2Props: fee224Data,
};

export const fee225Data = {
  number: "250",
  className: "",
};

export const tag1024217Data = {
  text15: "500ct-1.00kg",
  fee2Props: fee225Data,
};

export const fee226Data = {
  number: "300",
  className: "",
};

export const tag1024218Data = {
  text15: "1kg +",
  className: "tag-1024-5",
  fee2Props: fee226Data,
};

export const tag10245Data = {
  x029: "Carat Weight",
  className: "tag-1024-1",
};

export const fee227Data = {
  number: "25",
  className: "",
};

export const tag1024219Data = {
  text15: "≤1.99",
  className: "tag-1024-5",
  fee2Props: fee227Data,
};

export const fee228Data = {
  number: "40",
  className: "",
};

export const tag1024220Data = {
  text15: "5.00-19.99",
  className: "tag-1024-5",
  fee2Props: fee228Data,
};

export const fee229Data = {
  number: "60",
  className: "",
};

export const tag1024221Data = {
  text15: "50.00-99.99",
  className: "tag-1024-5",
  fee2Props: fee229Data,
};

export const fee230Data = {
  number: "100",
  className: "",
};

export const tag1024222Data = {
  text15: "200.00-499.99",
  className: "tag-1024-5",
  fee2Props: fee230Data,
};

export const fee231Data = {
  number: "200",
  className: "",
};

export const tag1024223Data = {
  text15: "1.00kg +",
  className: "tag-1024-5",
  fee2Props: fee231Data,
};

export const tag10246Data = {
  x029: "Carat Weight",
};

export const fee232Data = {
  number: "30",
  className: "",
};

export const tag1024224Data = {
  text15: "2.00-4.99",
  fee2Props: fee232Data,
};

export const fee233Data = {
  number: "55",
  className: "",
};

export const tag1024225Data = {
  text15: "20.00-49.99",
  fee2Props: fee233Data,
};

export const fee234Data = {
  number: "80",
  className: "",
};

export const tag1024226Data = {
  text15: "100.00-199.99",
  fee2Props: fee234Data,
};

export const fee235Data = {
  number: "150",
  className: "",
};

export const tag1024227Data = {
  text15: "500ct-1.00kg",
  fee2Props: fee235Data,
};

export const tag10247Data = {
  x029: "Type",
  className: "tag-1024-1",
};

export const fee236Data = {
  number: "33",
  className: "",
};

export const tag1024228Data = {
  text15: "1 Pearl",
  className: "tag-1024-5",
  fee2Props: fee236Data,
};

export const tag10248Data = {
  x029: "Type",
};

export const fee237Data = {
  number: "110",
  className: "",
};

export const tag1024229Data = {
  text15: "Pearl String",
  fee2Props: fee237Data,
};

export const tag10249Data = {
  x029: "Type",
  className: "tag-1024-2",
};

export const fee238Data = {
  number: "10 for ≤50 reports + 0.10 for each extra report",
  className: "",
};

export const tag1024230Data = {
  text15: "Packing & Admin",
  className: "tag-1024-6",
  fee2Props: fee238Data,
};

export const tag102410Data = {
  x029: "Carat Weight",
};

export const tag102411Data = {
  x029: "Carat Weight",
};

export const fee239Data = {
  number: "25",
  className: "",
};

export const tag1024231Data = {
  text15: "≤0.99",
  fee2Props: fee239Data,
};

export const fee240Data = {
  number: "25/ct",
  className: "",
};

export const tag1024232Data = {
  text15: "1.00 +",
  fee2Props: fee240Data,
};

export const fee241Data = {
  number: "35",
  className: "",
};

export const tag1024233Data = {
  text15: "Yield Calculation ≤0.99",
  fee2Props: fee241Data,
};

export const fee242Data = {
  number: "35/ct",
  className: "",
};

export const tag1024234Data = {
  text15: "Yield Calculation 1.00 +",
  fee2Props: fee242Data,
};

export const tag102412Data = {
  x029: "Type",
  className: "tag-1024-2",
};

export const fee243Data = {
  number: "Price Of The <br />diamond + 6",
  className: "fee-117",
};

export const tag1024235Data = {
  text15: "1 Diamond Jewelry",
  className: "tag-1024-7-1",
  fee2Props: fee243Data,
};

export const fee244Data = {
  number: "46",
  className: "fee-118",
};

export const tag1024236Data = {
  text15: "Many Diamonds Jewelry ≤0.99",
  className: "tag-1024-7-2",
  fee2Props: fee244Data,
};

export const fee245Data = {
  number: "Price of diamond lot + 6",
  className: "fee-119",
};

export const tag1024237Data = {
  text15: "Many Diamonds Jewelry 1.00+",
  className: "tag-1024-8-1",
  fee2Props: fee245Data,
};

export const tag102413Data = {
  x029: "Type",
};

export const fee246Data = {
  number: "Price Of The <br />colored Stone + 6",
  className: "fee-120",
};

export const tag1024238Data = {
  text15: "1 Colored Stone Jewelry",
  className: "tag-1024-9-1",
  fee2Props: fee246Data,
};

export const fee247Data = {
  number: "Price of all <br />colored stones + 6",
  className: "fee-121",
};

export const tag1024239Data = {
  text15: "Many Colored Stones Jewelry",
  className: "tag-1024-9-2",
  fee2Props: fee247Data,
};

export const fee248Data = {
  number: "Price Of Diamonds + <br />price Of Colored <br />stones + 6",
  className: "fee-122",
};

export const tag1024240Data = {
  text15: "Mixed Stones Jewelry",
  className: "tag-1024-10-1",
  fee2Props: fee248Data,
};

export const tag102414Data = {
  x029: "Type",
  className: "tag-1024-2",
};

export const fee249Data = {
  number: "Normal Price -2",
  className: "",
};

export const tag1024241Data = {
  text15: "Online Report",
  className: "tag-1024-6",
  fee2Props: fee249Data,
};

export const fee250Data = {
  number: "+30",
  className: "",
};

export const tag1024242Data = {
  text15: "Luxury Report",
  className: "tag-1024-6",
  fee2Props: fee250Data,
};

export const fee251Data = {
  number: "+10",
  className: "",
};

export const tag1024243Data = {
  text15: "Laser Out (only Diamond)",
  className: "tag-1024-6",
  fee2Props: fee251Data,
};

export const fee252Data = {
  number: "+5",
  className: "",
};

export const tag1024244Data = {
  text15: "H & A (only Diamond)",
  className: "tag-1024-6",
  fee2Props: fee252Data,
};

export const fee253Data = {
  number: "15",
  className: "",
};

export const tag1024245Data = {
  text15: "Duplicate",
  className: "tag-1024-6",
  fee2Props: fee253Data,
};

export const fee254Data = {
  number: "16",
  className: "fee-128",
};

export const tag1024246Data = {
  text15: "Recheck With Seal",
  className: "tag-1024-7-3",
  fee2Props: fee254Data,
};

export const fee255Data = {
  number: "10",
  className: "",
};

export const tag1024247Data = {
  text15: "Quick Check (identification Only)",
  className: "tag-1024-6",
  fee2Props: fee255Data,
};

export const fee256Data = {
  number: "20",
  className: "",
};

export const tag1024248Data = {
  text15: "Video Service",
  className: "tag-1024-6",
  fee2Props: fee256Data,
};

export const fee257Data = {
  number: "Price Of Total Carat <br />weight + 5 For Each <br />extra Stone",
  className: "fee-131",
};

export const tag1024249Data = {
  text15: "Colored Stone Lot Report (uniform)",
  className: "tag-1024-8",
  fee2Props: fee257Data,
};

export const fee258Data = {
  number: "+50%",
  className: "fee-132",
};

export const tag1024250Data = {
  text15: "Fast Service (next Day) (no Origin)",
  className: "tag-1024-7",
  fee2Props: fee258Data,
};

export const tag102415Data = {
  x029: "Type",
};

export const fee259Data = {
  number: "+10",
  className: "",
};

export const tag1024251Data = {
  text15: "Large Report",
  fee2Props: fee259Data,
};

export const fee260Data = {
  number: "+6",
  className: "",
};

export const tag1024252Data = {
  text15: "Seal",
  fee2Props: fee260Data,
};

export const fee261Data = {
  number: "+10",
  className: "",
};

export const tag1024253Data = {
  text15: "Laser In (only Diamond)",
  fee2Props: fee261Data,
};

export const fee262Data = {
  number: "+10",
  className: "",
};

export const tag1024254Data = {
  text15: "Fancy Color (only Diamond)",
  fee2Props: fee262Data,
};

export const fee263Data = {
  number: "10",
  className: "",
};

export const tag1024255Data = {
  text15: "Recheck Without Seal",
  fee2Props: fee263Data,
};

export const fee264Data = {
  number: "+33",
  className: "fee-138",
};

export const tag102432Data = {
  x029: "Origin(ruby,sapphire,emerald,paraiba)",
  fee2Props: fee264Data,
};

export const fee265Data = {
  number: "20",
  className: "",
};

export const tag1024256Data = {
  text15: "Emerald Clarity Enhancement Test",
  fee2Props: fee265Data,
};

export const fee266Data = {
  number: "5/picture",
  className: "",
};

export const tag1024257Data = {
  text15: "Picture Service",
  fee2Props: fee266Data,
};

export const fee267Data = {
  number: "42 For ≤1.00 Ct + 5 <br />for Each Extra Carat",
  className: "fee-141",
};

export const tag1024258Data = {
  text15: "Diamond Lot Report (uniform)",
  className: "tag-1024-10",
  fee2Props: fee267Data,
};

export const fee268Data = {
  number: "+100%",
  className: "fee-142",
};

export const tag1024259Data = {
  text15: "Super-fast Service (same Day) (no Origin)",
  className: "tag-1024-9",
  fee2Props: fee268Data,
};








export const tag1Data = {
  x029: "Carat Weight",
};

export const fee1Data = {
  number: "30",
  className: "",
};

export const tag21Data = {
  text5: "≤0.29",
  feeProps: fee1Data,
};

export const fee2Data = {
  number: "40",
  className: "",
};

export const tag22Data = {
  text5: "0.50-0.99",
  feeProps: fee2Data,
};

export const tag3Data = {
  x029: "Carat Weight",
  className: "tag-1",
};

export const fee3Data = {
  number: "35",
  className: "",
};

export const tag23Data = {
  text5: "0.30-0.49",
  className: "tag-11",
  feeProps: fee3Data,
};

export const fee4Data = {
  number: "42/ct",
  className: "",
};

export const tag24Data = {
  text5: "1.00+",
  className: "tag-13",
  feeProps: fee4Data,
};

export const tag4Data = {
  x029: "Carat Weight",
};

export const fee5Data = {
  number: "27",
  className: "",
};

export const tag25Data = {
  text5: "≤0.49",
  feeProps: fee5Data,
};

export const fee6Data = {
  number: "37",
  className: "",
};

export const tag26Data = {
  text5: "1.00-1.99",
  feeProps: fee6Data,
};

export const fee7Data = {
  number: "50",
  className: "",
};

export const tag27Data = {
  text5: "3.00-4.99",
  feeProps: fee7Data,
};

export const fee8Data = {
  number: "80",
  className: "",
};

export const tag28Data = {
  text5: "10.00-19.99",
  feeProps: fee8Data,
};

export const fee9Data = {
  number: "120",
  className: "",
};

export const tag29Data = {
  text5: "50.00-99.99",
  feeProps: fee9Data,
};

export const fee10Data = {
  number: "200",
  className: "",
};

export const tag210Data = {
  text5: "200.00-499.99",
  feeProps: fee10Data,
};

export const fee11Data = {
  number: "300",
  className: "",
};

export const tag211Data = {
  text5: "1kg +",
  feeProps: fee11Data,
};

export const tag5Data = {
  x029: "Carat Weight",
  className: "tag-3",
};

export const fee12Data = {
  number: "32",
  className: "",
};

export const tag212Data = {
  text5: "0.50-0.99",
  className: "tag-22",
  feeProps: fee12Data,
};

export const fee13Data = {
  number: "45",
  className: "",
};

export const tag213Data = {
  text5: "2.00-2.99",
  className: "tag-23",
  feeProps: fee13Data,
};

export const fee14Data = {
  number: "60",
  className: "",
};

export const tag214Data = {
  text5: "5.00-9.99",
  className: "tag-24",
  feeProps: fee14Data,
};

export const fee15Data = {
  number: "100",
  className: "",
};

export const tag215Data = {
  text5: "20.00-49.99",
  className: "tag-25",
  feeProps: fee15Data,
};

export const fee16Data = {
  number: "150",
  className: "",
};

export const tag216Data = {
  text5: "100.00-199.99",
  className: "tag-26",
  feeProps: fee16Data,
};

export const fee17Data = {
  number: "250",
  className: "",
};

export const tag217Data = {
  text5: "500ct-1.00kg",
  className: "tag-27",
  feeProps: fee17Data,
};

export const tag6Data = {
  x029: "Carat Weight",
};

export const fee18Data = {
  number: "25",
  className: "",
};

export const tag218Data = {
  text5: "≤1.99",
  feeProps: fee18Data,
};

export const fee19Data = {
  number: "40",
  className: "",
};

export const tag219Data = {
  text5: "5.00-19.99",
  feeProps: fee19Data,
};

export const fee20Data = {
  number: "60",
  className: "",
};

export const tag220Data = {
  text5: "50.00-99.99",
  feeProps: fee20Data,
};

export const fee21Data = {
  number: "100",
  className: "",
};

export const tag221Data = {
  text5: "200.00-499.99",
  feeProps: fee21Data,
};

export const fee22Data = {
  number: "200",
  className: "",
};

export const tag222Data = {
  text5: "1.00kg +",
  feeProps: fee22Data,
};

export const tag7Data = {
  x029: "Carat Weight",
  className: "tag-5",
};

export const fee23Data = {
  number: "30",
  className: "",
};

export const tag223Data = {
  text5: "2.00-4.99",
  className: "tag-33",
  feeProps: fee23Data,
};

export const fee24Data = {
  number: "55",
  className: "",
};

export const tag224Data = {
  text5: "20.00-49.99",
  className: "tag-34",
  feeProps: fee24Data,
};

export const fee25Data = {
  number: "80",
  className: "",
};

export const tag225Data = {
  text5: "100.00-199.99",
  className: "tag-35",
  feeProps: fee25Data,
};

export const fee26Data = {
  number: "150",
  className: "",
};

export const tag226Data = {
  text5: "500ct-1.00kg",
  className: "tag-36",
  feeProps: fee26Data,
};

export const tag8Data = {
  x029: "Type",
  className: "tag-6",
};

export const fee27Data = {
  number: "33",
  className: "",
};

export const tag227Data = {
  text5: "1 Pearl",
  feeProps: fee27Data,
};

export const tag9Data = {
  x029: "Type",
  className: "tag-7",
};

export const fee28Data = {
  number: "110",
  className: "",
};

export const tag228Data = {
  text5: "Pearl String",
  className: "tag-38",
  feeProps: fee28Data,
};

export const tag10Data = {
  x029: "Type",
  className: "tag-8",
};

export const fee29Data = {
  number: "10 for ≤50 reports + 0.10 for each extra report",
  className: "",
};

export const tag229Data = {
  text5: "Packing & Admin",
  feeProps: fee29Data,
};

export const tag11Data = {
  x029: "Carat Weight",
};

export const fee30Data = {
  number: "25",
  className: "",
};

export const tag230Data = {
  text5: "≤0.99",
  className: "flex-col-8-item",
  feeProps: fee30Data,
};

export const fee31Data = {
  number: "35",
  className: "",
};

export const tag231Data = {
  text5: "Yield Calculation ≤0.99",
  className: "flex-col-8-item",
  feeProps: fee31Data,
};

export const tag12Data = {
  x029: "Carat Weight",
  className: "tag-10",
};

export const fee32Data = {
  number: "25/ct",
  className: "",
};

export const tag232Data = {
  text5: "1.00 +",
  className: "tag-40",
  feeProps: fee32Data,
};

export const fee33Data = {
  number: "35/ct",
  className: "",
};

export const tag233Data = {
  text5: "Yield Calculation 1.00 +",
  className: "tag-41",
  feeProps: fee33Data,
};

export const tag13Data = {
  x029: "Type",
};

export const fee34Data = {
  number: "Price Of The Diamond + 6",
  className: "fee-48",
};

export const tag234Data = {
  text5: "1 Diamond Jewelry",
  className: "tag-42",
  feeProps: fee34Data,
};

export const fee35Data = {
  number: "46",
  className: "fee-49",
};

export const tag235Data = {
  text5: "Many Diamonds Jewelry ≤0.99",
  className: "tag-43",
  feeProps: fee35Data,
};

export const fee36Data = {
  number: "Price of diamond lot + 6",
  className: "fee-50",
};

export const tag236Data = {
  text5: "Many Diamonds Jewelry 1.00+",
  className: "tag-44",
  feeProps: fee36Data,
};

export const tag14Data = {
  x029: "Type",
  className: "tag-12",
};

export const fee37Data = {
  number: "Price Of The Colored Stone + 6",
  className: "fee-51",
};

export const tag237Data = {
  text5: "1 Colored Stone Jewelry",
  className: "tag-45",
  feeProps: fee37Data,
};

export const fee38Data = {
  number: "Price Of All Colored Stone + 6",
  className: "fee-52",
};

export const tag238Data = {
  text5: "Many Colored Stones Jewelry",
  className: "tag-46",
  feeProps: fee38Data,
};

export const fee39Data = {
  number: "Price Of Diamonds + Price Of Colored Stones + 6",
  className: "fee-53",
};

export const tag239Data = {
  text5: "Mixed Stones Jewelry",
  className: "tag-47",
  feeProps: fee39Data,
};

export const tag15Data = {
  x029: "Type",
};

export const fee40Data = {
  number: "Normal Price -2",
  className: "",
};

export const tag240Data = {
  text5: "Online Report",
  feeProps: fee40Data,
};

export const fee41Data = {
  number: "+30",
  className: "",
};

export const tag241Data = {
  text5: "Luxury Report",
  feeProps: fee41Data,
};

export const fee42Data = {
  number: "+10",
  className: "",
};

export const tag242Data = {
  text5: "Laser Out (only Diamond)",
  feeProps: fee42Data,
};

export const fee43Data = {
  number: "+5",
  className: "",
};

export const tag243Data = {
  text5: "H & A (only Diamond)",
  feeProps: fee43Data,
};

export const fee44Data = {
  number: "15",
  className: "",
};

export const tag244Data = {
  text5: "Duplicate",
  feeProps: fee44Data,
};

export const fee45Data = {
  number: "16",
  className: "",
};

export const tag245Data = {
  text5: "Recheck With Seal",
  feeProps: fee45Data,
};

export const fee46Data = {
  number: "10",
  className: "",
};

export const tag246Data = {
  text5: "Quick Check (identification Only)",
  feeProps: fee46Data,
};

export const fee47Data = {
  number: "20",
  className: "",
};

export const tag247Data = {
  text5: "Video Service",
  feeProps: fee47Data,
};

export const fee48Data = {
  number: "Price Of Total Carat Weight + 5 For Each Extra Stone",
  className: "fee-62",
};

export const tag32Data = {
  x029: "Colored Stone Lot Report (uniform)",
  feeProps: fee48Data,
};

export const fee49Data = {
  number: "+50%",
  className: "",
};

export const tag248Data = {
  text5: "Fast Service (next Day) (no Origin)",
  feeProps: fee49Data,
};

export const tag16Data = {
  x029: "Type",
  className: "tag-14",
};

export const fee50Data = {
  number: "+10",
  className: "",
};

export const tag249Data = {
  text5: "Large Report",
  className: "tag-57",
  feeProps: fee50Data,
};

export const fee51Data = {
  number: "+6",
  className: "",
};

export const tag250Data = {
  text5: "Seal",
  className: "tag-58",
  feeProps: fee51Data,
};

export const fee52Data = {
  number: "+10",
  className: "",
};

export const tag251Data = {
  text5: "Laser In (only Diamond)",
  className: "tag-59",
  feeProps: fee52Data,
};

export const fee53Data = {
  number: "+10",
  className: "",
};

export const tag252Data = {
  text5: "Fancy Color (only Diamond)",
  className: "tag-60",
  feeProps: fee53Data,
};

export const fee54Data = {
  number: "10",
  className: "",
};

export const tag253Data = {
  text5: "Recheck Without Seal",
  className: "tag-61",
  feeProps: fee54Data,
};

export const fee55Data = {
  number: "+33",
  className: "",
};

export const tag254Data = {
  text5: "Origin(ruby,sapphire,emerald,paraiba)",
  className: "tag-62",
  feeProps: fee55Data,
};

export const fee56Data = {
  number: "20",
  className: "",
};

export const tag255Data = {
  text5: "Emerald Clarity Enhancement Test",
  className: "tag-63",
  feeProps: fee56Data,
};

export const fee57Data = {
  number: "5/picture",
  className: "",
};

export const tag256Data = {
  text5: "Picture Service",
  className: "tag-64",
  feeProps: fee57Data,
};

export const fee58Data = {
  number: "42 For ≤1.00 Ct + 5 For Each Extra Carat",
  className: "fee-72",
};

export const tag33Data = {
  x029: "Diamond Lot Report (uniform)",
  className: "tag-16",
  feeProps: fee58Data,
};

export const fee59Data = {
  number: "+100%",
  className: "",
};

export const tag257Data = {
  text5: "Super-fast Service (same Day) (no Origin)",
  className: "tag-65",
  feeProps: fee59Data,
};















export const tag3751Data = {
  x029: "Carat Weight",
};

export const tag37521Data = {
  text10: "≤0.29",
  number: "30",
};

export const tag37522Data = {
  text10: "0.30-0.49",
  number: "35",
};

export const tag37523Data = {
  text10: "0.50-0.99",
  number: "40",
};

export const tag37524Data = {
  text10: "1.00+",
  number: "42/ct",
};

export const tag3753Data = {
  x029: "Carat Weight",
};

export const tag37525Data = {
  text10: "≤0.49",
  number: "27",
};

export const tag37526Data = {
  text10: "0.50-0.99",
  number: "32",
};

export const tag37527Data = {
  text10: "1.00-1.99",
  number: "37",
};

export const tag37528Data = {
  text10: "2.00-2.99",
  number: "45",
};

export const tag37529Data = {
  text10: "3.00-4.99",
  number: "50",
};

export const tag375210Data = {
  text10: "5.00-9.99",
  number: "60",
};

export const tag375211Data = {
  text10: "10.00-19.99",
  number: "80",
};

export const tag375212Data = {
  text10: "20.00-49.99",
  number: "100",
};

export const tag375213Data = {
  text10: "50.00-99.99",
  number: "120",
};

export const tag375214Data = {
  text10: "100.00-199.99",
  number: "150",
};

export const tag375215Data = {
  text10: "200.00-499.99",
  number: "200",
};

export const tag375216Data = {
  text10: "500ct-1.00kg",
  number: "250",
};

export const tag375217Data = {
  text10: "1kg +",
  number: "300",
};

export const tag3754Data = {
  x029: "Carat Weight",
};

export const tag375218Data = {
  text10: "≤1.99",
  number: "25",
};

export const tag375219Data = {
  text10: "2.00-4.99",
  number: "30",
};

export const tag375220Data = {
  text10: "5.00-19.99",
  number: "40",
};

export const tag375221Data = {
  text10: "20.00-49.99",
  number: "55",
};

export const tag375222Data = {
  text10: "50.00-99.99",
  number: "60",
};

export const tag375223Data = {
  text10: "100.00-199.99",
  number: "80",
};

export const tag375224Data = {
  text10: "200.00-499.99",
  number: "100",
};

export const tag375225Data = {
  text10: "500ct-1.00kg",
  number: "150",
};

export const tag375226Data = {
  text10: "1.00kg +",
  number: "200",
};

export const tag3755Data = {
  x029: "Type",
};

export const tag375227Data = {
  text10: "1 Pearl",
  number: "33",
};

export const tag375228Data = {
  text10: "Pearl String",
  number: "110",
};

export const tag3756Data = {
  x029: "Type",
};

export const tag375229Data = {
  text10: "Packing & Admin",
  number: "10 for ≤50 reports + 0.10 for each extra report",
};

export const tag3757Data = {
  x029: "Carat Weight",
};

export const tag375230Data = {
  text10: "≤0.99",
  number: "25",
};

export const tag375231Data = {
  text10: "1.00 +",
  number: "25/ct",
};

export const tag375232Data = {
  text10: "Yield Calculation ≤0.99",
  number: "35",
};

export const tag375233Data = {
  text10: "Yield Calculation 1.00 +",
  number: "35/ct",
};

export const tag3758Data = {
  x029: "Type",
};

export const tag375234Data = {
  text10: "1 Diamond Jewelry",
  number: "Price Of The <br />diamond + 6",
  className: "tag-375-1-34",
};

export const tag375235Data = {
  text10: "Many Diamonds Jewelry ≤0.99",
  number: "46",
  className: "tag-375-1-35",
};

export const tag375236Data = {
  text10: "Many Diamonds Jewelry 1.00+",
  number: "Price of diamond lot + 6",
  className: "tag-375-1-36",
};

export const tag375237Data = {
  text10: "1 Colored Stone Jewelry",
  number: "Price Of The <br />colored Stone + 6",
  className: "tag-375-1-37",
};

export const tag375238Data = {
  text10: "Many Colored Stones Jewelry",
  number: "Price Of All <br />colored Stone + 6",
  className: "tag-375-1-38",
};

export const tag375239Data = {
  text10: "Mixed Stones Jewelry",
  number: "Price Of Diamonds + <br />price Of Colored <br />stones + 6",
  className: "tag-375-1",
};

export const group472Data = {
  className: "group-48",
};


export const price1024Data = {
  globalData: globalData,
  rectangle21: "img/rectangle-21-2.png",
  title: "ALGT REPORT PRICE LIST",
  feePdf: "Download Our Testing Price List",
  name1: "Diamond Identification Report (loose)",
  coloredStoneIdenti: "Colored Stone Identification Report (loose)",
  rubySapphireEmeral: "RUBY,SAPPHIRE,EMERALD,ALEXANDRITE,PARAIBA",
  otherColoredStonesLoose: "Other Colored Stones (loose)",
  name2: "Pearl Identification Report",
  extraServiceForDelivery: "Extra Service For Delivery",
  roughDiamondIdenti: "Rough Diamond Identification Report (loose)",
  jewelryIdentificationReport: "Jewelry Identification Report",
  additionalService: "Additional Service",
  tag10241Props: tag10241Data,
  tag10242Props: tag10242Data,
  tag102421Props: tag102422Data,
  tag102422Props: tag102423Data,
  tag102423Props: tag102424Data,
  tag102424Props: tag102425Data,
  tag10243Props: tag10243Data,
  tag10244Props: tag10244Data,
  tag102425Props: tag102426Data,
  tag102426Props: tag102427Data,
  tag102427Props: tag102428Data,
  tag102428Props: tag102429Data,
  tag102429Props: tag1024210Data,
  tag1024210Props: tag1024211Data,
  tag1024211Props: tag1024212Data,
  tag1024212Props: tag1024213Data,
  tag1024213Props: tag1024214Data,
  tag1024214Props: tag1024215Data,
  tag1024215Props: tag1024216Data,
  tag1024216Props: tag1024217Data,
  tag1024217Props: tag1024218Data,
  tag10245Props: tag10245Data,
  tag1024218Props: tag1024219Data,
  tag1024219Props: tag1024220Data,
  tag1024220Props: tag1024221Data,
  tag1024221Props: tag1024222Data,
  tag1024222Props: tag1024223Data,
  tag10246Props: tag10246Data,
  tag1024223Props: tag1024224Data,
  tag1024224Props: tag1024225Data,
  tag1024225Props: tag1024226Data,
  tag1024226Props: tag1024227Data,
  tag10247Props: tag10247Data,
  tag1024227Props: tag1024228Data,
  tag10248Props: tag10248Data,
  tag1024228Props: tag1024229Data,
  tag10249Props: tag10249Data,
  tag1024229Props: tag1024230Data,
  tag102410Props: tag102410Data,
  tag102411Props: tag102411Data,
  tag1024230Props: tag1024231Data,
  tag1024231Props: tag1024232Data,
  tag1024232Props: tag1024233Data,
  tag1024233Props: tag1024234Data,
  tag102412Props: tag102412Data,
  tag1024234Props: tag1024235Data,
  tag1024235Props: tag1024236Data,
  tag1024236Props: tag1024237Data,
  tag102413Props: tag102413Data,
  tag1024237Props: tag1024238Data,
  tag1024238Props: tag1024239Data,
  tag1024239Props: tag1024240Data,
  tag102414Props: tag102414Data,
  tag1024240Props: tag1024241Data,
  tag1024241Props: tag1024242Data,
  tag1024242Props: tag1024243Data,
  tag1024243Props: tag1024244Data,
  tag1024244Props: tag1024245Data,
  tag1024245Props: tag1024246Data,
  tag1024246Props: tag1024247Data,
  tag1024247Props: tag1024248Data,
  tag1024248Props: tag1024249Data,
  tag1024249Props: tag1024250Data,
  tag102415Props: tag102415Data,
  tag1024250Props: tag1024251Data,
  tag1024251Props: tag1024252Data,
  tag1024252Props: tag1024253Data,
  tag1024253Props: tag1024254Data,
  tag1024254Props: tag1024255Data,
  tag10243Props2: tag102432Data,
  tag1024255Props: tag1024256Data,
  tag1024256Props: tag1024257Data,
  tag1024257Props: tag1024258Data,
  tag1024258Props: tag1024259Data,
};

export const price1920Data = {
  globalData: globalData,
  rectangle21: "img/rectangle-21-2.png",
  algtReportPriceList: "ALGT REPORT PRICE LIST",
  feePdf: "Download Our Testing Price List",
  name1: "Diamond Identification Report (loose)",
  coloredStoneIdenti: "Colored Stone Identification Report (loose)",
  rubySapphireEmeral: "RUBY,SAPPHIRE,EMERALD,ALEXANDRITE,PARAIBA",
  otherColoredStonesLoose: "Other Colored Stones (loose)",
  name2: "Pearl Identification Report",
  extraServiceForDelivery: "Extra Service For Delivery",
  roughDiamondIdenti: "Rough Diamond Identification Report (loose)",
  jewelryIdentificationReport: "Jewelry Identification Report",
  additionalService: "Additional Service",
  tag1Props: tag1Data,
  tag21Props: tag21Data,
  tag22Props: tag22Data,
  tag2Props: tag3Data,
  tag23Props: tag23Data,
  tag24Props: tag24Data,
  tag3Props: tag4Data,
  tag25Props: tag25Data,
  tag26Props: tag26Data,
  tag27Props: tag27Data,
  tag28Props: tag28Data,
  tag29Props: tag29Data,
  tag210Props: tag210Data,
  tag211Props: tag211Data,
  tag4Props: tag5Data,
  tag212Props: tag212Data,
  tag213Props: tag213Data,
  tag214Props: tag214Data,
  tag215Props: tag215Data,
  tag216Props: tag216Data,
  tag217Props: tag217Data,
  tag5Props: tag6Data,
  tag218Props: tag218Data,
  tag219Props: tag219Data,
  tag220Props: tag220Data,
  tag221Props: tag221Data,
  tag222Props: tag222Data,
  tag6Props: tag7Data,
  tag223Props: tag223Data,
  tag224Props: tag224Data,
  tag225Props: tag225Data,
  tag226Props: tag226Data,
  tag7Props: tag8Data,
  tag227Props: tag227Data,
  tag8Props: tag9Data,
  tag228Props: tag228Data,
  tag9Props: tag10Data,
  tag229Props: tag229Data,
  tag10Props: tag11Data,
  tag230Props: tag230Data,
  tag231Props: tag231Data,
  tag11Props: tag12Data,
  tag232Props: tag232Data,
  tag233Props: tag233Data,
  tag12Props: tag13Data,
  tag234Props: tag234Data,
  tag235Props: tag235Data,
  tag236Props: tag236Data,
  tag13Props: tag14Data,
  tag237Props: tag237Data,
  tag238Props: tag238Data,
  tag239Props: tag239Data,
  tag14Props: tag15Data,
  tag240Props: tag240Data,
  tag241Props: tag241Data,
  tag242Props: tag242Data,
  tag243Props: tag243Data,
  tag244Props: tag244Data,
  tag245Props: tag245Data,
  tag246Props: tag246Data,
  tag247Props: tag247Data,
  tag31Props: tag32Data,
  tag248Props: tag248Data,
  tag15Props: tag16Data,
  tag249Props: tag249Data,
  tag250Props: tag250Data,
  tag251Props: tag251Data,
  tag252Props: tag252Data,
  tag253Props: tag253Data,
  tag254Props: tag254Data,
  tag255Props: tag255Data,
  tag256Props: tag256Data,
  tag32Props: tag33Data,
  tag257Props: tag257Data,
};

export const price375Data = {
  globalData: globalData,
  algtReportPriceList: "ALGT REPORT PRICE LIST",
  name1: "Diamond Identification Report (loose)",
  coloredStoneIdenti: "Colored Stone Identification Report (loose)",
  rubySapphireEmeral: "RUBY,SAPPHIRE,EMERALD,ALEXANDRITE,PARAIBA",
  otherColoredStonesLoose: "Other Colored Stones (loose)",
  name2: "Pearl Identification Report",
  extraServiceForDelivery: "Extra Service For Delivery",
  roughDiamondIdenti: "Rough Diamond Identification Report (loose)",
  jewelryIdentificationReport: "Jewelry Identification Report",
  tag3751Props: tag3751Data,
  tag37521Props: tag37521Data,
  tag37522Props: tag37522Data,
  tag37523Props: tag37523Data,
  tag37524Props: tag37524Data,
  tag3752Props: tag3753Data,
  tag37525Props: tag37525Data,
  tag37526Props: tag37526Data,
  tag37527Props: tag37527Data,
  tag37528Props: tag37528Data,
  tag37529Props: tag37529Data,
  tag375210Props: tag375210Data,
  tag375211Props: tag375211Data,
  tag375212Props: tag375212Data,
  tag375213Props: tag375213Data,
  tag375214Props: tag375214Data,
  tag375215Props: tag375215Data,
  tag375216Props: tag375216Data,
  tag375217Props: tag375217Data,
  tag3753Props: tag3754Data,
  tag375218Props: tag375218Data,
  tag375219Props: tag375219Data,
  tag375220Props: tag375220Data,
  tag375221Props: tag375221Data,
  tag375222Props: tag375222Data,
  tag375223Props: tag375223Data,
  tag375224Props: tag375224Data,
  tag375225Props: tag375225Data,
  tag375226Props: tag375226Data,
  tag3754Props: tag3755Data,
  tag375227Props: tag375227Data,
  tag375228Props: tag375228Data,
  tag3755Props: tag3756Data,
  tag375229Props: tag375229Data,
  tag3756Props: tag3757Data,
  tag375230Props: tag375230Data,
  tag375231Props: tag375231Data,
  tag375232Props: tag375232Data,
  tag375233Props: tag375233Data,
  tag3757Props: tag3758Data,
  tag375234Props: tag375234Data,
  tag375235Props: tag375235Data,
  tag375236Props: tag375236Data,
  tag375237Props: tag375237Data,
  tag375238Props: tag375238Data,
  tag375239Props: tag375239Data,
  group47Props: group472Data,
};
